














































































































































































































































































































































































































































































































































































































































































@import "~@/assets/styles/components/table";
.bug-input {
  position: relative;
  top: -4px;
}
.v-data-table thead tr th,
.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: $colorPrimary !important;
}
.v-btn--contained.button-premio {
  font-size: 14px;
  border-radius: 0 !important;
  color: $colorPrimary !important;
  border: 1px solid $colorPrimary !important;
  box-shadow: none !important;
  font-weight: normal !important;
  &-alto {
    border-radius: 0 !important;
    font-size: 14px;
    font-weight: normal !important;
    box-shadow: transparent !important;
    color: white !important;
  }
}
.box-table {
  // border-top: 5px solid $colorPrimary;
  // border-top-left-radius: 0 !important;
  // border-top-right-radius: 0 !important;

  .v-data-table-header-mobile {
    display: none;
  }

  &.box-table-ganhadores {
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      // padding: 15px 0;
    }

    .v-data-table tbody tr.v-data-table__expanded__content {
      box-shadow: none;
    }
  }

  .box-table-palpites {
    .v-data-table {
      margin: 20px 0;
    }
  }
}

.fab-cadastrar-usuario {
  position: fixed;
  z-index: 1;
  bottom: 25px;
  right: 5px;

  @media (min-width: 599px) {
    display: none;
  }
}
.position-buttons {
  @media (max-width: 600px) {
    text-align: center;
  }
}
